import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import config from "../util/config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Attack Layer 3",
    },
  },
};

const AttackLayer3 = () => {
  const [labels, setLabels] = useState([]);
  const [attackLayer, setAttackLayer] = useState({});

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Attack Layer 3",
        data: attackLayer?.total?.timestamps.map(
          (data, index) => attackLayer?.total?.values[index]
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  useEffect(() => {
    axios.get(`${config.apiURL}/attack-layer3`).then((res) => {
      //console.log(res.data);
      const response = res.data;
      setAttackLayer(response.data);
      setLabels(response.data.total.timestamps);
    });
  }, []);

  return <Line options={options} data={data} />;
};

export default AttackLayer3;
