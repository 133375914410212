import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import TrafficChangeChart from "./components/TrafficChangeChart";
import AttackLayer3 from "./components/AttackLayer3";
import PopularDomains from "./components/PopularDomains";

const App = () => {
  return (
    <>
      <div class="container">
        <div class="row title">
          <h5>S23 General Assignment</h5>
        </div>
        <div class="row chart traffic">
          <Card>
            <Card.Header>Traffic Change Chart</Card.Header>
            <Card.Body>
              <TrafficChangeChart />
            </Card.Body>
          </Card>
        </div>
        <div class="row list">
          <Card>
            <Card.Header>Popular Domains List</Card.Header>
            <Card.Body>
              <PopularDomains />
            </Card.Body>
          </Card>
        </div>
        <div class="row chart ddos">
          <Card>
            <Card.Header>Layer 3 DDoS Attack Chart</Card.Header>
            <Card.Body>
              <AttackLayer3 />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default App;
